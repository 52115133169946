<template>
  <header>
    <nav class="navbar fixed-top navbar-expand-md navbar-light bg-light">
      <div class="container-fluid">
        <router-link :to="{ name: 'Home' }" class="navbar-brand">
          <img
            id="navbar-logo"
            src="@/assets/img/RoundHere_logo_152x80.png"
            :class="{ small: isScrolled }"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <span
                class="btn btn-lg btn-primary mb-1"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Offerings')"
                >Invest</span
              >
            </li>
            <li class="nav-item">
              <!-- <a class="btn btn-lg btn-primary" :href="raiseURL">Raise</a> -->
              <span
                class="btn btn-lg btn-primary mb-1"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Raise')"
                >Raise</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('KnowledgeBase')"
                >Knowledge Base</span
              >
            </li>

            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('About')"
                >About</span
              >
            </li>

            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Contact')"
                >Contact</span
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { onUnmounted, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const isScrolled = ref(false);
    const raiseURL = ref(process.env.VUE_APP_RAISE_URL);
    const handleScroll = () => {
      isScrolled.value =
        document.body.scrollTop > 50 || document.documentElement.scrollTop > 50;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      console.log(process.env.VUE_APP_RAISE_URL);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const closeMenuRoute = routeName => {
      router.push({ name: routeName });
    };

    return { handleScroll, isScrolled, raiseURL, closeMenuRoute };
  }
};
</script>
<style>
#navbar-logo.small {
  height: 40px;
}
</style>
