import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      Title: "Round Here",
    }
  },
  {
    path: '/rh/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/rh/raise',
    name: 'Raise',
    component: () => import('../views/Raise.vue'),
  },
  {
    path: '/rh/kb',
    name: 'KnowledgeBase',
    component: () => import('../views/KnowledgeBase.vue'),
  },
  {
    path: '/rh/offerings',
    name: 'Offerings',
    component: () => import('../views/offerings/Offerings.vue'),
    props: true,
  },
  {
    path: '/rh/post/:id',
    name: 'Post',
    component: () => import('../views/Post.vue'),
    props: true,
  },
  {
    path: '/rh/tags/:tag',
    name: 'OfferingsTag',
    component: () => import('../views/offerings/OfferingsTag.vue'),
  },
  {
    path: '/rh/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue'),
    props: true,
  },
  {
    path: '/rh/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
    props: true,
  },
  {
    path: '/rh/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/:id',
    name: 'Offering',
    component: () => import('../views/offerings/Offering.vue'),
    props: true,
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      console.log("To Hash: ", to.hash);
      const position = {};
      position.el = to.hash;
      position.top = 80;
      //TODO need to delay for page to load (or handle onMount)
      if (document.querySelector(to.hash)) {
        console.log("Query Selector: to.hash:", to.hash);
        return position;
      }
    } else if (savedPosition) {
      console.log("Saved Position! ToHash:", to.hash);
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
